













import { computed, defineComponent } from '@vue/composition-api';
import DemandCard from '@/components/DemandCard.vue';
import { useApi } from '@/util/useApi';
import { getDemands } from '../Demand/api';
import { useMainStore } from '@/store';
export default defineComponent({
  components: { DemandCard },
  props: {
    hospital: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    // const { result: demands } = useApi(() =>
    //   getDemands({ page: 1, size: 999, hospital: props.hospital }),
    // );

    const { state } = useMainStore();

    return {
      demands: computed(() => state.hospitalDemand[props.hospital]),
    };
  },
});
