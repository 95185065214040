







































import { computed, defineComponent } from '@vue/composition-api';
import Results from './Results.vue';
import Search from '../Patent/Search.vue';
import { useMainStore } from '@/store';
import { Hospital } from '@/store/api/model';

export default defineComponent({
  components: { Results, Search },
  setup(_, { root }) {
    const { state } = useMainStore();
    const detail = computed<Hospital>(
      () =>
        state.hospitals.find((h) => h.id.toString() === root.$route.params.id) || ({} as Hospital),
    );

    return { detail };
  },
});
