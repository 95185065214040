





















import { defineComponent, PropType, ref } from '@vue/composition-api';
import VClamp from 'vue-clamp';
import { Demand } from '@/views/Demand/api';
import { useDemandStore } from '@/views/Demand/store';
import RandomImage from './RandomImage.vue';
import RequiredContact from './RequiredContact.vue';
export default defineComponent({
  components: { VClamp, RandomImage, RequiredContact },
  props: {
    index: { type: Number, default: 1 },
    demand: {
      type: Object as PropType<Demand>,
      required: true,
    },
  },
  emits: ['required'],
  setup(props, { root }) {
    const showRequiredDialog = ref(false);

    return {
      showRequiredDialog,
      toDemandDetail: (item: Demand) => {
        root.$router.push({ name: 'DemandDetail', params: { id: item.no } });
      },
    };
  },
});
